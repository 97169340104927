import React from "react"
import { Link } from "gatsby"
// import { StaticImage } from "gatsby-plugin-image"
import { Layout } from "components/layout"
import { Seo } from "components/seo"
import { Breadcrumb } from "components/breadcrumb"
// import { Aside } from "components/blog/aside"
// import { ShareButtons } from "components/share-buttons"
import { DefaultHeader } from "components/headers/default-header"
import { CvBtn } from "../../components/case_study/cv-btn"
import { BlogContainer } from "components/blog-container"
import { Author } from "../../components/author"

const ExamplesOfUtilization = ({ location }) => {
  const title =
    "あのブランドはどうやってファンを獲得した?PARKLoT 活用事例4 選を解説!"
  const list = [
    { title: "ブログ", link: "/blog" },
    { title: title, link: location.pathname },
  ]

  return (
    <>
      <DefaultHeader />
      <Layout>
        <Seo
          pagetitle="パークロットTwitter活用事例 | 参考になるキャンペーン内容と導入事例"
          pagedesc="PARKLoTを導入し、ファン獲得・ブランディング・認知向上・来店増加を成功させた実例を紹介します。自社製品に最適なSNSマーケティングを選ぶコツが学べます。低コストでも成果の出る販促をお考えの担当者に。"
          pageimg="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/blog/diversity-casual-teenager-team-success-winning-concept.jpg"
          pagepath="/blog/examples-of-utilization"
        />
        <Breadcrumb title="よくある質問" items={list} />
        <BlogContainer>
          <main>
            <div className="blog__meta">
              <Link to={`/blog`}>お役立ち情報</Link>
              本記事は5分で読むことができます
            </div>
            <h1>
              あのブランドはどうやってファンを獲得した?PARKLoT活用事例4選を解説!
            </h1>
            <div className="blog__sns-container">
              {/* {typeof window !== "undefined" && window.location.href && (
                <ShareButtons
                  articleUrl={window.location.href}
                  articleTitle={title}
                />
              )} */}
            </div>
             {/* 更新日 */}
            <time className="blog__time mr-4 font-bold empty:hidden"></time>
            <time className="blog__time">公開日：2022年5月18日</time>
            <figure className="blog__thumb">
              <img
                placeholder="tracedSVG"
                alt="ジャンプしている男女"
                src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/blog/diversity-casual-teenager-team-success-winning-concept.jpg"
                className="w-full"
              />
              <a href="https://www.freepik.com/photos/diverse-group">
                Photo by rawpixel.com
              </a>
            </figure>
            <p>
              こんにちは、PARKLoT代表の松田信介です！
              <br />
              本日は、PARKLoTをお使いいただき、ファンの獲得やブランディングを成功させた企業様の事例をユースケースとして、PARKLoTが独自に分析した記事をお届けします。
              <br />
              <br />
            </p>
            <p>
              この記事は、以下の課題に悩まれる個人事業主や中小企業の担当者の方々にオススメです。
            </p>
            <ul>
              <li>
                事業を開始したばかりで、マーケティングに割く予算や人材が限られている
              </li>
              <li>
                商品やサービス、ブランドの認知度を向上させる方法がわからない
              </li>
              <li>
                商品には自信があるが、全国にいる潜在的なファンへのアプローチ方法がわからない
              </li>
              <li>他社の SNS マーケティングの方法を知りたい</li>
            </ul>
            <p>
              この記事は、これまでに、PARKLoTをお使いいただいた企業様がどのようなSNSマーケティングを行い、ブランディングを成功に導いたかを分析し、ノウハウ化した事例集です。
              <br />
              読者の皆様 SNS マーケティングのお役に立てば幸いです。
            </p>
            <p>それではさっそくユースケースを見ていきましょう!</p>

            <div className="toc">
              <p className="toc__title">目次</p>
              <ol className="toc__list">
                <li className="toc__item">
                  <a href="#id1" className="toc__link">
                    ユースケース1:エシカル系商品のストーリーを押し出したマーケティング
                  </a>
                  <ol className="toc__child_list">
                    <li className="toc__item">
                      <a href="#id2" className="toc__link">
                        ・ストーリーを伝え、共感を呼ぶ。オウンドメディアとの連携
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id3" className="toc__link">
                        ・心理学テクニックを駆使した導線設計
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id4" className="toc__link">
                        ・まとめ
                      </a>
                    </li>
                  </ol>
                </li>
                <li className="toc__item">
                  <a href="#id5" className="toc__link">
                    ユースケース2:応募者全員へのプレゼント企画によるコスメ商品の認知度向上・初回利用施策
                  </a>
                  <ol className="toc__child_list">
                    <li className="toc__item">
                      <a href="#id6" className="toc__link">
                        ・期待値を高めるクリエイティブの訴求力と継続利用施策
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id7" className="toc__link">
                        ・運用コストの減少
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id8" className="toc__link">
                        ・まとめ
                      </a>
                    </li>
                  </ol>
                </li>
                <li className="toc__item">
                  <a href="#id9" className="toc__link">
                    ユースケース3:自社店舗への訪問へ繋げるSNSマーケティング
                  </a>
                  <ol className="toc__child_list">
                    <li className="toc__item">
                      <a href="#id10" className="toc__link">
                        ・日常的に利用する商品の引換券による実店舗利用者へ直接訴求
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id11" className="toc__link">
                        ・データから見える商品開発方針と費用対効果
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id12" className="toc__link">
                        ・まとめ
                      </a>
                    </li>
                  </ol>
                </li>
                <li className="toc__item">
                  <a href="#id13" className="toc__link">
                    ユースケース4:定期的なサービス認知施策とクイズコンテンツ
                  </a>
                  <ol className="toc__child_list">
                    <li className="toc__item">
                      <a href="#id14" className="toc__link">
                        ・心理学的に裏付けされた継続的なキャンペーン企画の実施によるサービス認知
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id15" className="toc__link">
                        ・コンテンツマーケティングの王道「クイズ」
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id16" className="toc__link">
                        ・連続性のあるキャンペーンでより大きな企画へ挑戦
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id17" className="toc__link">
                        ・まとめ
                      </a>
                    </li>
                  </ol>
                </li>
                <li className="toc__item">
                  <a href="#id18" className="toc__link">
                    まとめ
                  </a>
                </li>
              </ol>
            </div>

            <div>
              <h2 id="id1">
                ユースケース1:エシカル系商品のストーリーを押し出したマーケティング
              </h2>
              <p>
                近年話題のSDGsに代表される環境負荷に配慮した商品のSNSマーケティングにPARKLoTを活用いただきました。
                <br />
              </p>
              <p>
                ブランドを立ち上げられて間もないタイミングでしたが、効果的なキャンペーン企画により、
                <strong>2週間で3,000名弱のフォロワー数を獲得</strong>
                し、認知度向上に繋がりました。
                <br />
              </p>
              <p>
                2週間のキャンペーン期間中のフォロワー数の伸びと、SNS利用者の導線を図にしてみました。
              </p>
              <img
                className="blog__article-image"
                placeholder="tracedSVG"
                alt="フォロワー数の伸びと導線"
                src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/blog/utilization-usecase1.png"
              />

              <h3 id="id2">
                ストーリーを伝え、共感を呼ぶ。オウンドメディアとの連携
              </h3>
              <p>
                こちらの企業様は、商品の開発ストーリーや商品を通して実現したいビジョンなどを記事化し発信するオウンドメディアをnote上で展開されています。
              </p>
              <p>
                オウンドメディアを運用する利点としては、TwitterなどSNSだけでは語りきれない「思い」を伝えることが可能となります。
              </p>
              <p>
                SNSはタイムライン方式でコンテンツが流れてしまうのに対し、オウンドメディアはストック型のコンテンツとして機能します。
              </p>
              <p>
                <strong>
                  ソーシャルメディアとオウンドメディアの長所をうまく生かしたマーケティングスタイル
                </strong>
                です。
              </p>

              <h3 id="id3">心理学テクニックを駆使した導線設計</h3>
              <p>
                キャンペーン企画を実施したTweetでは
                <strong>
                  景品の一部の内容を明らかにせず、企画の詳細を記載した記事をオウンドメディアに掲載しTweetにリンクを貼ることで、疑問をもった利用者にnoteの記事を読んでもらい、企画内容に興味をもってもらえる導線設計
                </strong>
                を作りました。
              </p>
              <p>
                これにより、応募者がストーリーやビジョンに理解や共感し、ブランドのファンを増やす結果に繋がっています。
              </p>
              <p>
                情報の一部を隠して意欲を高める心理効果は「ツァイガルニク効果」と呼ばれます。CMに入る前のテレビ番組の演出で「まさかの結末が!」といったテロップは、その続きを知りたいと思わせる効果があります。
              </p>
              <p>
                実際に、キャンペーン企画への反応は、キャンペーンへの応募者からの関連リツイート内の好意的なコメントからも感じることができます。
              </p>

              <h3 id="id4">まとめ</h3>
              <p>
                エシカル系の商品の話題性もさることながら、抽選対象の景品を商品やクーポン、ギフト券だけにするのではなく、
                <strong>
                  企画アイディアによって興味を引き、好奇心をそそる景品を盛り込む
                </strong>
                ことで、ブランディングに貢献した好例と言えます。
              </p>
              <p>
                Twitterでは伝えきれない情報や商品開発に対する思いを伝える導線設計が秀逸でした。
              </p>
            </div>

            <div>
              <h2 id="id5">
                ユースケース2:応募者全員へのプレゼント企画によるコスメ商品の認知度向上・初回利用施策
              </h2>
              <p>
                女性向けコスメ商品を展開されている企業様が、クリスマス商戦期間にかけて実施されたECサイトと連動したキャンペーン企画です。
              </p>
              <p>
                すでに自社内でキャンペーン企画を実施されていましたが、当選連絡業務に負荷がかかり、運営コストに課題を感じていらっしゃいました。
                <br />
                そこで、キャンペーン企画の業務がシステム化されたPARKLoTをご活用いただき、結果4,000人以上ものフォロワー数の増加に繋がりました。
              </p>
              <p>
                キャンペーンの抽選内容は、自社コスメ商品とAmazonギフト券に加え、Wチャンスとして
                <strong>
                  応募者全員へ商品割引のクーポンのプレゼントを実施
                </strong>
                されました。
              </p>
              <p>
                以下はキャンペーン実施後のフォロワー数の伸びと、キャンペーン企画での利用者体験の流れを図にしたものです。
              </p>
              <img
                className="blog__article-image"
                placeholder="tracedSVG"
                alt="フォロワー数の伸びと利用者体験の流れ"
                src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/blog/utilization-usecase2.png"
              />

              <h3 id="id6">
                期待値を高めるクリエイティブの訴求力と継続利用施策
              </h3>
              <p>
                他人へのプレゼントや自分へのご褒美など購入意欲の上がるクリスマス時期でのキャンペーン実施が効果的でした。
              </p>
              <p>
                気になった瞬間に気軽に応募でき、その場で抽選結果がわかるインスタントウィンならではの特徴を生かし、
                <strong>
                  応募者全員に商品のクーポンを同時配布することで、商品の購入意欲を刺激し、ECサイト上でクーポンを利用して購入まで至ることができる設計
                </strong>
                になっています。
              </p>

              <h3 id="id7">運用コストの減少</h3>
              <p>
                応募者の中から当選数を指定して、抽選を行い、ウェブストア等で使えるデジタルクーポンをTwitter経由で当選者に発行する一連の作業はすべてPARKLoTのシステムを用いて一気通貫で行えます。
              </p>
              <p>
                本来これを人手で行うと、応募者の管理、抽選作業、当選者への連絡
                など膨大な作業になります。
                <br />
                PARKLoTをお使いいただくことで、これらにかかる人件費などの運用コストを減らし、商品開発や顧客対応などの本来の業務にリソースを割り振ることができます。
              </p>

              <h3 id="id8">まとめ</h3>
              <p>
                「抽選」かつ「全員当選」という期待値を高めるクリエイティブの訴求やその後の初回利用も含めたクーポン配布など考え抜かれたキャンペーン設計・企画でした。
              </p>
              <p>
                競合の多いジャンルでの商品展開だからこそ、PARKLoTの特徴である運用コストを抑えつつ、利用者の体験を損なわずに商品購入に繋げるSNSマーケティングを行うことで、ファンの心を掴み、商品のブランディングに成功された事例でした。
              </p>
            </div>

            <div>
              <h2 id="id9">
                ユースケース3:自社店舗への訪問へ繋げるSNSマーケティング
              </h2>
              <p>
                すでに全国的に認知度のあるブランドを冠するローカル企業様が、地域限定商品のファンを着実に増やし、販売促進を行うSNSマーケティングとして、PARKLoTをお使いいただいています。
              </p>
              <p>
                フォロー&amp;リツートしてくれた応募者に、自社商品の嗜好品のクーポンを景品とするキャンペーン企画です。
              </p>
              <p>
                7日間のキャンペーン企画の反響グラフと利用者体験の流れを図にしました。
              </p>
              <img
                className="blog__article-image"
                placeholder="tracedSVG"
                alt="リツイート数グラフと利用者体験の流れ"
                src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/blog/utilization-usecase3.png"
              />

              <h3 id="id10">
                日常的に利用する商品の引換券による実店舗利用者へ直接訴求
              </h3>
              <p>
                仕事の合間にとるコーヒーブレイクタイムなど、多くの人が日常的に求める嗜好品の引換券を景品にすることで、SNSユーザーにキャンペーンへの参加をしやすくしています。
              </p>
              <p>
                こちらの企業様の狙いは、実店舗を訪れる可能性がある利用者に向けた店舗訪問へのきっかけづくりや商品の認知度向上となります。
              </p>
              <p>
                商品の引換券を景品にすると、Amazonギフト券を景品にした場合のリツート数よりは低くなりがちですが、継続的なフォローが見込め、さらには
                <strong>店舗訪問による売上への貢献が期待</strong>できます。
              </p>

              <h3 id="id11">データから見える商品開発方針と費用対効果</h3>
              <p>
                キャンペーンを通して得た応募者数やリツート内容などのデータは、新商品の継続販売や発売停止など、商品開発に欠かせない意思決定の材料にすることができます。
              </p>
              <p>
                また、自社商品であれば原価も既知のため、
                <strong>CPF(コストあたりのフォロワー獲得数)を算出可能</strong>
                になり、SNS マーケティングの費用対効果が把握できます。
              </p>
              <p>
                ブランディングや商品販売にテレビCMに多額の広告費を投下している競合他社も多い中、低コストで効果的なマーケティングになっています。
              </p>

              <h3 id="id12">まとめ</h3>
              <p>
                このように、SNSマーケティングツールを利用することで、低コストでフォロワーを獲得しつつ、実店舗への訪問を促しながら、商品開発にも役立つデータが得られる、3拍子揃ったマーケティングを実施することができます。
              </p>
            </div>

            <div>
              <h2 id="id13">
                ユースケース4:定期的なサービス認知施策とクイズコンテンツ
              </h2>
              <p>
                こちらの企業様は、利用者にエコにつながる行動を起こしてもらい、その行動に応じたポイントを付与するウェブサービスを展開されています。
              </p>
              <p>
                サービス開始時期は2021年であり、これからサービスの認知度を高めていくフェーズです。SNSマーケティングをすでに実施されており、SDGsに関連するトリビアやTips、クイズを主軸とするコンテンツを展開されてきましたが、フォロワー数はもとより各ツイートのいいね数やリツート数などのアクション数の増加への課題感をお持ちでした。
              </p>
              <p>
                2022年よりPARKLoTを継続的にご利用いただくことにより、フォロワーの9,000名超えを達成されました。
              </p>
              <p>
                各キャンペーン内容と期間、反響結果を以下の表にまとめてみました。
              </p>
              <table>
                <thead>
                  <tr>
                    <th> </th>
                    <th className="blog__text-center">景品</th>
                    <th className="blog__text-center">期間</th>
                    <th className="blog__text-center">目的</th>
                    <th className="blog__text-center">結果</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="blog__text-center">第1弾</td>
                    <td className="blog__text-center">高額Amazonギフト券</td>
                    <td className="blog__text-center">7日間</td>
                    <td className="blog__text-center">
                      フォロワー数増加
                      <br />
                      サービス認知
                    </td>
                    <td className="blog__text-center">約5,000リツイート</td>
                  </tr>
                  <tr>
                    <td className="blog__text-center">第2弾</td>
                    <td className="blog__text-center">
                      環境に配慮した商品とアマゾンギフト券
                    </td>
                    <td className="blog__text-center">7日間</td>
                    <td className="blog__text-center">
                      サービス名やサービス内容の認知
                    </td>
                    <td className="blog__text-center">約3,000リツイート</td>
                  </tr>
                  <tr>
                    <td className="blog__text-center">第3弾</td>
                    <td className="blog__text-center">
                      高額Amazonギフト券+LINE友達追加
                    </td>
                    <td className="blog__text-center">7日間</td>
                    <td className="blog__text-center">
                      フォロワー数+LINE友達数増加
                      <br />
                      サービス認知
                      <br />
                      サービス利用者増
                    </td>
                    <td className="blog__text-center">約2,600リツイート</td>
                  </tr>
                  <tr>
                    <td className="blog__text-center">第4弾</td>
                    <td className="blog__text-center">
                      高額Amazonギフト券(第3弾より高額)+LINE友達追加
                    </td>
                    <td className="blog__text-center">7日間</td>
                    <td className="blog__text-center">
                      フォロワー数増加+LINE友達数増加
                      <br />
                      サービス認知
                      <br />
                      サービス利用者増
                    </td>
                    <td className="blog__text-center">約5,300リツイート</td>
                  </tr>
                </tbody>
              </table>

              <h3 id="id14">
                心理学的に裏付けされた継続的なキャンペーン企画の実施によるサービス認知
              </h3>
              <p>
                継続的なキャンペーン企画は、「ザイオンス効果」と呼ばれる、何度も接触するうちに、親近感が湧いたり親しみやすくなる心理学的にも解明された効果により、SNSユーザーの目に留まり、サービス利用などのアクションを行う利用者の増加に繋がります。
              </p>
              <p>
                また、キャンペーン参加条件にLINEの友達追加を取り込むことで、Twitterに加え、よりクローズドなコミュニケーションが期待できるLINE上でユーザーとの接点を増やすことができ、自社サービス利用へ繋げることが可能になります。
              </p>

              <h3 id="id15">コンテンツマーケティングの王道「クイズ」</h3>
              <p>
                フォロワー数の獲得のあとは、フォローを継続してもらい、サービス利用に繋げることが重要です。そのためには、投稿コンテンツとその見せ方がカギになります。
              </p>
              <p>
                こちらの企業様は、投稿コンテンツの柱としてクイズを実施されています。
              </p>
              <p>
                クイズがマーケティングに有効な点として、心理学でいう「サイガニック効果」と呼ばれる、
                <strong>
                  「問題を解きたい」「答えを知りたい」「焦らされて興味を惹かれる」という心理状態
                </strong>
                にさせます。
              </p>
              <p>
                答えを知ったあとは、知的好奇心が満たされ、クイズの内容が身近な問題であるほど、その内容が誰かの役に立つのではないかと想起させ、Twitterでリツートというアクションに繋げることができます。
              </p>

              <h3 id="id16">連続性のあるキャンペーンでより大きな企画へ挑戦</h3>
              <p>
                キャンペーン企画に第一弾、第二弾と連続性があることを認知してもらうことで、新規応募者だけでなく、過去の応募者にも参加したいと思わせることができます。
              </p>
              <p>
                各キャンペーンに次回への参加協力を要請しておくなどのテキストを盛り込むことで、キャンペーンのスケールを時系列に沿ってより大きな内容にすることが可能です。
              </p>
              <p>
                これらは、「フットインザドア・テクニック」と呼ばれ、
                <strong>
                  最初に小さな行動をした人は、その後により大きな行動をする可能性が高まるという現象を利用した手法
                </strong>
                です。
              </p>

              <h3 id="id17">まとめ</h3>
              <p>
                商品の販売促進だけでなく、ウェブサービスにおいても心理学を生かしたSNSマーケティングを活用することで、効果的にフォロワー数を増やし、ブランドの成長へ繋げることができた事例でした。
              </p>
            </div>

            <div>
              <h2 id="id18">まとめ</h2>
              <p>
                今回は4つのユースケースをもとにPARKLoTが独自に分析した記事をお送りしました。
              </p>
              <p>
                どの企業様も自社の商品やサービスの特徴とマッチしたSNSマーケティングを実施されていらっしゃいます。予算が限られている中で、効果的なSNSマーケティングによってファンを増やし、ブランドを育てる方法がわからないという課題をお持ちの方は、ご参考になれば幸いです。
              </p>
              <p>
                弊社では、今回挙げた4社のみならず、数多くの会社様にお使いいただき、さまざまな事例を有しております。
              </p>
              <p>
                こんな企画をTwitterキャンペーンで試してみたい!と思った方は、最短2分で始めることができます。PARKLoTをぜひご検討ください!
              </p>
              <p>
                良いアイディアが思い浮かばない!という方もご安心ください。当社のSNSマーケティングのプロが御社に最適なご提案をいたします!
                <br />
                まずは、下記のボタンから無料相談をご予約ください。
              </p>
            </div>
            <div className="case_study main">
              <CvBtn />
            </div>
            <Author name="matsuda" />
            {/* <div className="blog__sns-container--foot">
              {typeof window !== "undefined" && window.location.href && (
                <ShareButtons
                  articleUrl={window.location.href}
                  articleTitle={title}
                />
              )}
            </div> */}
            <div className="blog__more-articles">
              <h2 className="text-center">
                こちらの記事もあなたにおすすめです
              </h2>
              <ul>
                <li>
                  <a
                    href="/blog/present-campaign"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Twitterのフォロワーが少ない状態でのSNS運用は効果が薄い？プレゼントキャンペーンを実施して一気にフォロワーを獲得しましょう！
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/twitter-hashtag-campaign"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Twitterのハッシュタグキャンペーンとは？効果的なハッシュタグの見つけ方は？基本的なSNSマーケティング戦略を公開
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/maximize-campaign"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Twitterキャンペーンの効果を最大化させる方法 6選
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/why-parklot-is-inexpensive"
                    target="_blank"
                    rel="noreferrer"
                  >
                    PARKLoTはどうしてこんなに安くインスタントウィンが実施できるの？皆様の疑問にお答えします！
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/customer-appeal-SNS"
                    target="_blank"
                    rel="noreferrer"
                  >
                    訴求効果ピカイチ!!
                    手軽で効果的「SNS広告」2大スタイル徹底解説～フォロー＆リツイートキャンペーンとハッシュタグキャンペーンどちらをやるべき？
                    ～
                  </a>
                </li>
              </ul>
            </div>
          </main>
        </BlogContainer>
      </Layout>
    </>
  )
}
export default ExamplesOfUtilization
